





































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String || null,
      required: false,
      default: null,
    },
  },

  setup(props, { root }) {
    const { getMessageTemplateType } = useMisc({ root });

    const state = reactive({
      headers: [
        { value: "name", text: root.$tc("panel.event.add.alert.templateName") },
        { text: root.$tc("layout.misc.type"), value: "type", align: "right" },
        {
          text: root.$tc("layout.misc.default"),
          value: "default",
          align: "right",
        },
        { text: "", value: "actions", align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("message-template", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            search: state.search || undefined,
            type: props.type,
          },
        })
        .then(({ data: { messageTemplates, total } }) => {
          state.empty = false;
          state.items = messageTemplates.map(
            (messageTemplate: any, index: number) => ({
              id: index,
              name: "",
              ...messageTemplate,
            })
          );
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`message-template/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    watch(
      () => props.type,
      () => fetchData()
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const copyForm = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/${id}`)
        .then(({ data: { messageTemplate } }) => {
          state.loading = true;

          const name = prompt(
            "Podaj nazwę nowego szablonu:",
            `${messageTemplate.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post("message-template", {
                name,
                type: messageTemplate.type,
                title: messageTemplate.title,
                design: messageTemplate.design,
                template: messageTemplate.template,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("alert.cantCopyTemplate"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return { state, fetchData, deleteItem, copyForm, getMessageTemplateType };
  },
});
